<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%; max-height=90vh;" class="mx-auto">
        {{ $t('error.accountLockError') }}
        <p v-if="agencyPhoneNumber">
          {{ $t('error.accountUnlockInformation') }}
        </p>
        <v-row
          class="mt-2"
          v-for="agencyRecord in agencyRecords"
          :key="agencyRecord.key"
        >
          <v-col class="my-0 py-0">
            <p class="font-weight-bold mt-1">
              {{ $t(`header.agencyInfoErrorTable.${agencyRecord.key}`) }}
            </p>
          </v-col>
          <v-col class="my-0 py-0">
            <p class="mt-1">{{ agencyRecord.value }}</p>
          </v-col>
        </v-row>
        <v-row v-if="agencyPhoneNumber">
          <v-col class="my-0 py-0">
            <v-btn
              style="font-size:16px"
              color="next"
              rounded
              outlined
              :href="'tel:' + agencyPhoneNumber.replace(/-/g, '')"
            >
              <v-icon class="mr-0" size="12px" left>phone</v-icon>
              {{ $t('button.agencyTel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { getAgencies } from '@/apis/contractor/agencies';

export default {
  name: 'Lockout',
  components: {},
  data() {
    return {
      // 代理店情報
      agencyRecords: [],

      // 代理店電話番号
      agencyPhoneNumber: '',
    };
  },
  async mounted() {
    const agency = await getAgencies().catch(err => {
      return null;
    });
    if (agency) {
      // 代理店情報をセットする
      this.$set(this, 'agencyRecords', this.mapAgencyRecords(agency));
      this.$set(this, 'agencyPhoneNumber', agency.agencyPhoneNumber);
    }
  },
  methods: {
    // 代理店情報のマッピング
    mapAgencyRecords(agency) {
      // 表示項目
      const keys = ['agencyName', 'chargePersonName'];

      return keys.map(key => ({
        key: key,
        value: agency[key],
      }));
    },
  },
};
</script>
